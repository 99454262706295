@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/variables';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.center {
  flex-grow: 1;
  flex-shrink: 0;
  background: $color-black;
  overflow: hidden;
}

.navigation,
.footer {
  flex-grow: 0;
  flex-shrink: 0;
  background-color: $color-blue-persian;
}
