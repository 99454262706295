$color-blue-persian: #1717e5;

$color-black: #000;
$color-white: #fff;

$color-grey-silver: #ccc;
$color-grey-oslo: #8f9094;
$color-grey-emperor: #555;
$color-grey-abbey: #45474d;
$color-grey-mineshaft: #333;

$color-media-listen: #6612a6;
$color-media-watch: #ff7d0d;
$color-media-read: #f2330d;
$color-media-join: #ff0d4f;

$z-navigation: 3;
$z-dialog-wrapper: 3;
$z-featured-link: 500;
$z-section-inner: 2;
$z-background-elements: 0;
$z-tile-content: 1;
$z-tile-logo: 0;
$z-success-story: 4;

$page-max-width: 1140px;

$line-transparency: 0.4;

$indicator-horizontal-line-length: 250px;

$color-red-promegranate: #f2330d;
