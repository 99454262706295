@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/layout';

.featuredLink {
  background: linear-gradient(145deg, $color-media-join, $color-black);
  z-index: $z-featured-link;
}

.container {
  @include page-width;
  @include container;
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    margin: 0;
  }
}
