@mixin navigation-link($active-class) {
  position: relative;
  color: currentColor;
  text-decoration: none;
  padding: 5px 0 2px;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;

    transform-origin: left;

    position: absolute;
    left: 0;
    bottom: 0;

    background-image: linear-gradient(
      90deg,
      $color-white 0,
      $color-white 25%,
      transparent 0,
      transparent 50%,
      $color-white 0,
      $color-white 75%,
      transparent 0,
      transparent
    );

    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 400% 1px;

    transition: background-position 0.8s ease-in-out, opacity 0.3s;
    opacity: 0;
  }

  &.#{$active-class} {
    &::before {
      background-position: 66.666%;
    }
  }

  &:hover {
    &::before {
      background-position: 0;
      opacity: 1;
    }
  }
}
