@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/navigation-link';
@import 'shared/style-resources/mixins/typography';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navigation {
  position: fixed;
  z-index: $z-navigation;
  top: 0;
  left: 0;
  width: 100%;
  color: $color-white;

  background: transparent;
  transition: all 0.3s ease-out;

  &.menuActive {
    height: 100vh;
    background: $color-black;
    overflow: auto;
  }

  &:not(.menuActive) {
    &.scrolled {
      background: $color-black;
      box-shadow: 0 2px 11px 3px rgba(0, 0, 0, 0.5);
    }

    &.hidden {
      transform: translate(0, -110%);
    }
  }
}

.inner {
  @include page-width;
  @include container;
  padding-top: 16px;
  padding-bottom: 16px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  transition: padding-top 0.3s ease-out, padding-bottom 0.3s ease-out;
}

.home {
  display: block;
  padding: 2px 11px;
  border: 2px solid $color-white;
  white-space: nowrap;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
}

.links {
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 64px 0 0;

  .menuActive & {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: fadeIn 0.2s ease-in-out 0.1s both;
  }
}

.linkItem {
  @include font-orange;
  display: inline-flex;
  align-items: center;
  margin-top: 32px;

  &:first-child {
    margin-top: 0;
  }
}

.link {
  @include navigation-link('active');

  &.active {
    font-style: italic;
  }
}

@include small-screens {
  .inner {
    justify-content: space-between;
  }
}

@include medium-screens {
  .inner {
    .navigation.scrolled & {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .home {
    padding: 4px 14px;
    font-size: 16px;
  }

  .menuToggle {
    display: none;
  }

  .links {
    display: flex;
    margin: 0;
    width: auto;
  }

  .linkItem {
    @include font-peach;
    margin: 0 28px;
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@include large-screens {
  .inner {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@include medium-vertical-screens {
  .inner {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
