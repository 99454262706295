@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/resets';

.menuButton {
  @include reset-button;

  position: relative;
  width: 21px;
  height: 16px;
  transform-origin: center;
  transition: transform 0.2s ease-in-out;
}

.bar {
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 2px solid $color-white;
  transition: transform 0.2s ease-in-out;
}

.barTop {
  top: 0;
}

.barMiddle {
  top: calc(50% - 1px);
}

.barBottom {
  bottom: 0;
}

.active {
  &.menuButton {
    transform: rotate(-180deg);
  }

  .barTop {
    transform-origin: center;
    transform: translateY(7px) rotate(45deg);
  }

  .barMiddle {
    opacity: 0;
  }

  .barBottom {
    transform-origin: center;
    transform: translateY(-7px) rotate(-45deg);
  }
}
