/* stylelint-disable selector-class-pattern */

@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/typography';
@import '~a11y-css-reset/combo.css';

html {
  overflow-x: hidden;
}


body {
  @include font-family-sans;

  -moz-osx-font-smoothing: greyscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
  line-height: 1.65;
  color: $color-white;
  background: $color-black;
  overflow-x: hidden;

  ::selection {
    background: $color-blue-persian;
  }

  > * {
    opacity: 1 !important;
    transition: opacity 0.1s ease-in-out;
  }
}

.scroll-disabled {
  position: fixed;
}

.header-behind {
  header {
    z-index: 0;
  }
}

// stylelint-disable selector-max-id
body,
#__next {
  min-height: 100vh;
}
// stylelint-enable selector-max-id

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3 {
  font-size: 2em;
  font-weight: normal;
}

a {
  color: currentColor;
}

.page-transition-enter {
  opacity: 0;
}

.page-transition-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.page-transition-exit {
  opacity: 1;
}

.page-transition-exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.bg-elements-container {
  z-index: $z-background-elements;
  position: relative;
}

@include medium-screens {
  .scroll-disabled {
    position: static !important;
  }

  .header-behind {
    header {
      z-index: initial;
    }
  }
}
