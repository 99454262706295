@mixin reset-button {
  font-family: inherit;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: currentColor;
  font-size: inherit;
  line-height: inherit;
}

@mixin reset-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin reset-input {
  font-family: inherit;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: auto;
  color: currentColor;
  font-size: inherit;
  line-height: inherit;

  /* hide spinner Firefox */
  -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */

  /* hide spinner Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    margin: 0;
  }
}
