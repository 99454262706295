@import '../../../shared/style-resources/variables';
@import '../../../shared/style-resources/mixins/breakpoints';

$initial-expansion-duration: 3s;

$small-bubble-width: 500px;
$medium-bubble-width: 700px;
$large-bubble-width: 1000px;

$small-bubble-width-max: 750px;
$medium-bubble-width-max: 1000px;
$large-bubble-width-max: 1500px;

@mixin push-outside($amount) {
  &.left {
    left: 0;
    transform: translateX(-$amount);
  }

  &.right {
    right: 0;
    transform: translateX($amount);
  }
}

@keyframes twinkle {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

.bubble {
  position: absolute;
  pointer-events: none;
  backface-visibility: hidden; // prevent annoying flickering
  mix-blend-mode: lighten;
}

.sizing {
  padding-bottom: 100%;
}

.shape {
  background: radial-gradient(
    ellipse at center,
    transparentize($color-blue-persian, 0.25) 0%,
    transparentize($color-black, 0.25) 75%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all $initial-expansion-duration ease-in-out;

  .shrunk & {
    transform: scale(0.1);
  }

  .expanded & {
    transform: scale(1);
    animation: twinkle calc(#{$initial-expansion-duration} * 3) ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: $initial-expansion-duration;
  }

  .top.expanded &,
  .center.expanded & {
    animation: twinkle calc(#{$initial-expansion-duration} * 3) ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: $initial-expansion-duration;
  }
}

.small {
  width: $small-bubble-width;
}

.medium {
  width: $medium-bubble-width;
}

.large {
  width: $large-bubble-width;
}

.top {
  @include push-outside(40%);
  top: 0;
}

.middle {
  @include push-outside(50%);
  top: 50vh;
}

.bottom {
  @include push-outside(50%);
  display: none;
  bottom: 0;
}

.center {
  left: 0;

  &.small {
    transform: translateX(calc(50vw - #{$small-bubble-width} / 2));
  }

  &.medium {
    transform: translateX(calc(50vw - #{$medium-bubble-width} / 2))
      translateY(calc(#{$medium-bubble-width} / 2));
  }

  &.large {
    transform: translateX(calc(50vw - #{$large-bubble-width} / 2));
  }
}

@include medium-screens {
  .bottom {
    display: initial;
  }
}

@include large-screens {
  .small {
    width: $small-bubble-width-max;
  }

  .medium {
    width: $medium-bubble-width-max;
  }

  .large {
    width: $large-bubble-width-max;
  }

  .center {
    left: 0;

    &.small {
      transform: translateX(calc(50vw - #{$small-bubble-width-max} / 2));
    }

    &.medium {
      transform: translateX(calc(50vw - #{$medium-bubble-width-max} / 2));
    }

    &.large {
      transform: translateX(calc(50vw - #{$large-bubble-width-max} / 2));
    }
  }
}
