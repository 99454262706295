@import 'shared/style-resources/variables';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/navigation-link';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/typography';

.navigation {
  @include container;
  @include page-width;
  @include font-peach;

  padding-top: 24px;
  padding-bottom: 24px;
  color: $color-white;
}

.websiteLinks,
.socialLinks {
  @include reset-list;
  padding-top: 8px;
  padding-bottom: 8px;
}

.inner {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.websiteLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5%;
}

.websiteLinkItem {
  margin: 12px 0;
}

.websiteLink {
  @include navigation-link('active');
  white-space: nowrap;

  &.active {
    font-style: italic;
  }
}

.socialLinkItem {
  margin: 4px;
  white-space: nowrap;
}

.socialLinks {
  display: flex;
  margin: 0 -8px;
}

.socialLink {
  display: block;
  padding: 0 8px;

  img {
    height: 16px;
    width: auto;
  }
}

@include medium-screens {
  .navigation {
    min-height: 80px;
  }

  .inner {
    height: 100%;
    flex-direction: row;
    align-items: center;
  }

  .websiteLinkItem,
  .socialLinkItem {
    align-items: center;
  }

  .websiteLinks {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .websiteLinkItem {
    margin: 0 1em;

    &:first-child {
      margin-left: 0;
    }
  }

  .socialLinks {
    display: flex;
    flex-flow: row;
    margin: 0 -8px;
  }

  .socialLinkItem {
    display: inline-flex;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
