@mixin font-face($fontFamily, $fontName, $style, $weight) {
  font-family: $fontFamily;
  src: url('../../../public/fonts/IBM/#{$fontName}.ttf'),
    url('../../../public/fonts/IBM/#{$fontName}.woff2'),
    url('../../../public/fonts/IBM/#{$fontName}.woff');
  font-style: $style;
  font-weight: $weight;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  @include font-face('IBM Plex Sans', 'IBMPlexSans-Regular', normal, 400);
}
@font-face {
  @include font-face('IBM Plex Sans', 'IBMPlexSans-Bold', bold, 700);
}

@font-face {
  @include font-face('IBM Plex Mono', 'IBMPlexMono-Regular', normal, 400);
}
@font-face {
  @include font-face('IBM Plex Mono', 'IBMPlexMono-Bold', bold, 700);
}

@mixin font-family-sans {
  font-family: 'IBM Plex Sans', sans-serif;
}

@mixin font-family-mono {
  font-family: 'IBM Plex Mono', 'Courier New', Courier, monospace;
}

@mixin font-mango {
  font-size: 80px;
  line-height: 94px;
  letter-spacing: -2.14px;
}

@mixin font-banana {
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -1.93px;
}

@mixin font-strawberry {
  font-size: 64px;
  line-height: 88px;
}

@mixin font-pineapple {
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.86px;
}

@mixin font-melon {
  font-size: 48px;
  letter-spacing: -1.29px;
  line-height: 64px;
}

@mixin font-orange {
  font-size: 32px;
  line-height: 44px;
  letter-spacing: -0.86px;
}

@mixin font-apricot {
  font-size: 28px;
  line-height: 44px;
}

@mixin font-apple {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.64px;
}

@mixin font-kiwi {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.54px;
}

@mixin font-clementine {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.54px;
}

@mixin font-plum {
  font-size: 20px;
  line-height: 25px;
}

@mixin font-peach {
  font-size: 16px;
  line-height: 24px;
}

@mixin font-raspberry {
  font-size: 16px;
  line-height: 20px;
}

@mixin font-fig {
  font-size: 14px;
  line-height: 18px;
}

@mixin font-cherry {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.43px;
}

@mixin font-grape {
  font-size: 12px;
  line-height: 16px;
}

@mixin word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
