@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/variables';

.button {
  @include reset-button;
  @include font-fig;
  display: block;
  width: 100%;
  padding: 10px;

  text-align: center;
  font-weight: normal;

  transition: all 0.2s ease-in-out;

  + .button {
    margin-top: 16px;
  }

  &.large {
    @include font-clementine;
    padding: 16px 32px;
  }

  &.primary {
    background: $color-white;
    color: $color-blue-persian;
    border: 1px solid $color-white;
  }

  &.secondary {
    border: 1px solid $color-white;
    color: $color-white;
  }

  &.hoverBlack:hover {
    border: 1px solid $color-black;
    background: $color-black;
    color: $color-white;
  }

  &.hoverBlue:hover {
    border: 1px solid $color-blue-persian;
    background: $color-blue-persian;
    color: $color-white;
  }

  &[disabled] {
    opacity: 0.3;
  }
}
