@mixin small-screens {
  @media only screen and (min-width: 400px) {
    @content;
  }
}

@mixin medium-screens {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

//Extra breakpoint to scale components based on the viewport height
@mixin medium-vertical-screens {
  @media only screen and (min-width: 768px) and (max-height: 660px) {
    @content;
  }
}

@mixin medium-screens-only {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large-screens {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin xlarge-screens {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}

@mixin iphone-only {
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-width: 767px) {
      @content;
    }
  }
}
