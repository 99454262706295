@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/variables';

@mixin page-width {
  max-width: $page-max-width;
  margin: 0 auto;
}

@mixin container {
  margin: 0 auto;
  padding-right: 16px;
  padding-left: 16px;

  @include medium-screens {
    padding-right: 32px;
    padding-left: 32px;
  }
}

@mixin section-inner {
  position: relative;
  z-index: $z-section-inner;
}
