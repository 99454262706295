@import 'shared/style-resources/mixins/layout';
@import 'shared/style-resources/mixins/typography';
@import 'shared/style-resources/mixins/resets';
@import 'shared/style-resources/mixins/breakpoints';
@import 'shared/style-resources/variables';

.cookieBanner {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 11px 3px rgba(0, 0, 0, 0.5);

  &::before {
    content: '';
    position: fixed;
    z-index: 1;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background: transparentize($color-black, 0.3);
  }
}

.inner {
  position: relative;
  z-index: 2;
  background: $color-blue-persian;
}

.center {
  @include page-width;
  @include container;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content {
  max-width: 760px;
}

.headline {
  @include font-plum;
  font-weight: bold;
  flex-grow: 1;
  margin: 0;
}

.text {
  @include font-fig;
  margin-top: 16px;
  line-height: 22px;
}

.buttons {
  flex-shrink: 0;
  width: 100%;
  margin-top: 24px;
}

@include medium-screens {
  .center {
    padding-top: 32px;
    padding-bottom: 32px;
    flex-direction: row;
  }

  .content {
    padding-right: 32px;
  }

  .buttons {
    width: 200px;
    margin-top: 0;
  }
}
